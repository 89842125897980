import React, { useState } from 'react';
import LogInPage from './LogInPage';
import Scheduler from './Scheduler';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute';
import ErrorPage from './ErrorPage';
import RegisterPage from './RegisterPage';
import Main from './Main';
import InstantMessenger from './InstantMessenger';

function App() {
  return (
    <div>
      <Router>
          <Routes>
            <Route element={<Main/>} path="/" errorElement={<ErrorPage/>} />
            <Route element={<RegisterPage/>} path="/registerpage" />
            <Route element={<LogInPage/>} path="/loginpage" />
            <Route element={<ProtectedRoute />}>
                <Route element={<Scheduler/>} path="/scheduler" exact/>
            </Route>
            <Route element={<ProtectedRoute />}>
                <Route element={<InstantMessenger/>} path="/instantmessenger" />
            </Route>
           
            
          </Routes>
      </Router>
    </div>
  );
}

export default App;
