import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import Cookies from 'js-cookie';
import Axios from 'axios';
import './InstantMessenger.css';
import { io } from "socket.io-client";
{/*
// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:4000';

export const socket = io(URL);
 */}
const socket = io();


function InstantMessenger() {

  const [username, setUsername] = useState('');
  const [newChatUsername, setNewChatUsername] = useState('');
  const [newMessage, setNewMessage] = useState('');
  const navigate = useNavigate();

  const [showExpiredPopup, setShowExpiredPopup] = useState(false);

  const isAuthenticated = Cookies.get('session_id') !== undefined && Cookies.get('session_id') !== '';

  const [selectedUser, setSelectedUser] = useState("");
  const [selectedUserDMs, setSelectedUserDMs] = useState([]);
  const [recentUserDMs, setRecentUserDMs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userDMs, setUserDMs] = useState([]);
  const messageAreaRef = useRef(null);
  const [newMessageEvent, setNewMessageEvent] = useState([]);
  useEffect(() => {
    // Scroll to the bottom of the message area whenever messages change
    if (messageAreaRef.current) {
      messageAreaRef.current.scrollTop = messageAreaRef.current.scrollHeight;
    }
  }, [selectedUserDMs]);

  
  const getSelectedUserDMs = () => {
    const selectedUserMessages = userDMs.filter(
      (dm) => dm.to === selectedUser || dm.from === selectedUser
      
    );
    setSelectedUserDMs(selectedUserMessages);
  };

// Inside the getRecentUserDMs function
// Inside the getRecentUserDMs function
const getRecentUserDMs = () => {
  const uniqueUserDMs = {};

  // Filtering out messages from the current user and creating a unique message map
  userDMs.forEach(dm => {
    const associatedUser = dm.from === username ? dm.to : dm.from;

    // Exclude messages where the associated user is the current username
    if (associatedUser !== username) {
      if (!uniqueUserDMs[associatedUser] || dm.id > uniqueUserDMs[associatedUser].id) {
        uniqueUserDMs[associatedUser] = dm;
      }
    }
  });

  // Create a list of objects with associated user and message
  const recentUserDMsList = Object.entries(uniqueUserDMs).map(([associatedUser, dm]) => ({
    user: associatedUser,
    message: dm.message,
    id: dm.id,
  }));

  // Sorting messages by dm.id (decreasing order)
  const sortedRecentUserDMs = recentUserDMsList.sort((a, b) => b.id - a.id);

  setRecentUserDMs(sortedRecentUserDMs);
};
  




  const fetchUsername =  () => {
    
    if(isAuthenticated){
      Axios.post('/fetchusername', {
        session_id: Cookies.get("session_id"),
      }).then((response) => {
        setUsername(response.data);
      }).catch((error) => {
        handleShowExpiredPopup();
        console.log(error);
      });}
  }
  const fetchDMs =  () => {
    if(isAuthenticated){
      Axios.post('/fetchDMs', {
        session_id: Cookies.get("session_id"),
      }).then((response) => {
        const dmData = response.data;
        setUserDMs(dmData);
      }).catch((error) => {
        console.log(error);
      });}
   
  }


  const postDM =  () => {

    if(isAuthenticated){
      Axios.post('/postDM', {
        session_id: Cookies.get("session_id"),
        to: selectedUser,
        message: newMessage,
      }).then((response) => {
        socket.emit('newmessage', { to: selectedUser, from: response.data.name, message: newMessage, time: response.data.time});
      }).catch((error) => {
        console.log(error);
      });}
      setNewMessage('');
  }


  useEffect(() => {


    function onNewMessage() {
      fetchDMs();
    }
    socket.on('servermessage', onNewMessage);

      fetchUsername();
      fetchDMs();

      setIsLoading(false);
      
  }, []);

  useEffect(() => {
    if (!isLoading) {
      // After DMs are fetched and isLoading is false
      getSelectedUserDMs();
    }
  }, [selectedUser]);



  useEffect(() => {
    if (!isLoading) {
      // After DMs are fetched and isLoading is false
      getRecentUserDMs();
      if(selectedUser!==undefined&&selectedUser!==""){
        getSelectedUserDMs();
      }
    }
  }, [userDMs]); 



  const handleLogOut = () => {
    Axios.post('/logout', {
      session_id: Cookies.get("session_id"),
    });


    Cookies.set('session_id', '');
    handleOpenHomePage();
  }






  const handleShowExpiredPopup = () => {
    setShowExpiredPopup(true);
  }




  const handleOpenHomePage = () => {
    navigate('/');
  }

  const handleAddNewChat = () => {
    if(newChatUsername !== username){
    Axios.post('/checkusernameexists', {
      username: newChatUsername,
    }).then((response) => {
        setSelectedUser(response.data);
    }).catch((error) => {
      console.log(error);
    });
    setNewChatUsername("");
  }
  }

  const handleNewChatChange = (event) => {
    const cleanedValue = event.target.value.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
    setNewChatUsername(cleanedValue);
  };
  
  const handleSetNewMessage = (event) => {
    setNewMessage(event.target.value);
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission (no refresh)
      postDM(); // Call the function that handles sending
    }
  };
  const handleKeyPressUser = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission (no refresh)
      handleAddNewChat();
    }
  };

  return (
    <div className="messenger">
      <div className="header">
        <div className="title">
          <h1 className="title-text">Nico Milette</h1>
        </div>

        <div className="left-header">
          <p className='username-text'> Welcome, {username} </p>
          <button className="login-signup" onClick={handleLogOut}>
            Log Out
          </button>

          <button className="login-signup" onClick={handleOpenHomePage}>Return Home</button>
          
        </div>
      </div>

      <div className='im-content'>


        
      
            <div className='recent-messages'>
            <div className='search-bar'>
            <input value={newChatUsername} onChange={handleNewChatChange}onKeyDown={handleKeyPressUser} ></input>
            <button className="dropdown-button" onClick={handleAddNewChat} type='submit'>
              +
            </button>
            </div>
            <div className='recent-message-list'>
              {isLoading ? (
                <p>Loading...</p>
              ) : (
                recentUserDMs.length > 0 ? (
                  recentUserDMs.map((dm, index) => (
                    <button className='recent-message'key={index} onClick={()=>{setSelectedUser(dm.user);}}>
                      <h2>{dm.user}</h2>
                       
                       <p>{dm.message}</p>
                    </button>
                  ))
                ) : (
                  <p>No recent messages.</p>
                )
              )}
            </div>
         
            </div>

<div className='chat-area'>
{selectedUser !== "" && (
    <>
      <div className='chat-area-top'>
        
      

        <h2>{selectedUser}</h2>
        

      </div>

      <div className='message-area' ref={messageAreaRef} >
       
        

        {selectedUserDMs.map((dm, index) => (
              <div
                key={index}
                className={`message ${dm.to === selectedUser ? 'to-user' : 'from-user'}`}>
                  <p className={`message ${dm.to === selectedUser ? 'to-message' : 'from-message'}`}>{dm.message}</p>
              </div>
            ))}

      </div>
      <div className='input-area'>
        <input type="text" value={newMessage}onChange={handleSetNewMessage} onKeyDown={handleKeyPress}/>
        <button onClick={postDM}>Send</button>
      </div>
    </>
  )}
</div>


      </div>


      {showExpiredPopup && (
        <div className="expired-popup">
          <p>Your session has expired. You will be logged out.</p>
          <div className='expired-popup-buttons'>
            <button onClick={handleLogOut}>Okay</button>
          </div>
        </div>
      )}




    </div>
  );
}

export default InstantMessenger;
