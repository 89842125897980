import React, { useState, useEffect, useRef } from 'react';
import './Main.css';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Axios from 'axios';
import git from './git.png';
import node from './node.png';
import react from './react.png';
import mysql from './mysql.png';
import cpp from './cpp.png';


import gitvcs from './gitvcs.png';
import javas from './javaScript.png';
import html from './html.png';
import css from './Css.png';

function Main() {


  const isAuthenticated = Cookies.get('session_id') !== undefined && Cookies.get('session_id') !== '';
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [usernameVerified, setUsernameVerified] = useState(false);
  const [activeButton, setActiveButton] = useState("about");
  const [showNewBlogPostPopup, setShowNewBlogPostPopup] = useState(false);
  const [blogText, setBlogText] = useState("");
  const [blogPosts, setBlogPosts] = useState([]);
  const [activeDropdown, setActiveDropdown] = useState(false);

  const [showExipiredPopup, setShowExipiredPopup] = useState(false);

  const renderBlogPostsByMonth = () => {
    // Create a dictionary to group blog posts by month
    const blogPostsByMonth = {};

    blogPosts.forEach((post) => {
      const { month, year, blog_text } = post;
      const monthYear = `${month} ${year}`;

      if (!blogPostsByMonth[monthYear]) {
        blogPostsByMonth[monthYear] = [];
      }

      blogPostsByMonth[monthYear].push(blog_text);
    });

    // Render the blog posts under their corresponding months
    return Object.keys(blogPostsByMonth).map((monthYear) => (
      <div key={monthYear}>
        <h2>{monthYear}</h2>
        

<div className='blog-posts'>
        {blogPostsByMonth[monthYear].map((post, index) => (
          <div className='blog-post' key={index}>
            <p>{post}</p>
          </div>
        ))}</div>
      </div>
    ));
  };
  const handlePostNewBlog = () => {
    Axios.post('/postblog', {
      session_id: Cookies.get("session_id"),
      blog_text: blogText,
    }).then((response) => {
      console.log(response.data);
    }).catch((error) => {
      console.log(error);
    });
    setBlogText("");
    setShowNewBlogPostPopup(false);
  }
  
  const fetchBlogPosts = () => {
    Axios.post('/fetchblogposts', {
      session_id: Cookies.get("session_id"),
    }).then((response) => {
      const sortedPosts = response.data.sort((b,a) => a.id - b.id); // Sort by the database ID
        setBlogPosts(sortedPosts);
    }).catch((error) => {
      console.log(error);
    });
  }

  const fetchUsername = () => {
    if(isAuthenticated){
    Axios.post('/fetchusername', {
      session_id: Cookies.get("session_id"),
    }).then((response) => {
      setUsername(response.data);
    }).catch((error) => {
      handleShowExpiredPopup();
      console.log(error);
    });}
  }


  const handleNewBlogPostPopup = () => {
      setShowNewBlogPostPopup(true);
  }

 
  const handleShowExpiredPopup = () => {
    setShowExipiredPopup(true);
}

  useEffect(() => {

    fetchUsername();

    fetchBlogPosts();


  }, []);
  
  const handleButtonPress = (buttonName) => {
    setActiveButton(buttonName);    
    if(username==='nico'){
      setUsernameVerified(true);
    }
  };

  
  const handleOpenLogIn = () => {

    if(isAuthenticated){
      Axios.post('/logout', {
        session_id: Cookies.get("session_id"),
      });
  
  
      Cookies.set('session_id', '');
      window.location.reload();
    }
    else{

      navigate('/loginpage');
    }
  };
  const handleOpenInstantMessenger = () => {
    navigate('/instantmessenger');
  };
  
  const handleOpenScheduler = () => {
    navigate('/scheduler');
  };
  return (
    <div className="app">
    <div className="header">
      

      <div className="left-header">

      <h1 className="title-text">Nico Milette</h1>

        <div className="buttons">
          <button className="dropdown-button" onClick={() => handleButtonPress('about')}>
            About Me
          </button>
          <div className="dropdown">
            <button className="dropdown-button" onClick={() => handleButtonPress('projects')}>
              Projects
            </button>
          </div>

          <button className="dropdown-button" onClick={() => handleButtonPress('blog')}>
            Blog
          </button>
        </div>

 
      </div>

      <div className='right-header'>
        
        <button className="login-signup" onClick={handleOpenLogIn}>
          {isAuthenticated ? 'Log Out' : 'Log In'}
        </button>
        <p className='username-text'>{isAuthenticated ? `Welcome, ${username}` : ''}</p>
        </div>
    </div>
      

      
    <div className="content">

      <div className='content-two'>


        {activeButton === 'about' && (
          <>

          <div className='about-content'>
            <h1>About me</h1>
            <p>I am a backend developer with experience in web design applications. I am currently pursuing a Bachelor of Science degree in Computer Science. </p>

            <h1>Familiar Technologies</h1>
            <div className='about-tech-tiles'>

              
            <div className='about-tech-tile'>
                <a ><img src={javas} alt="JavaScript" className='about-tech-pic' /></a>
                <h1>JavaScript</h1>
              </div>


              <div className='about-tech-tile'>
                <a ><img src={node} alt="NodeJS" className='about-tech-pic' /></a>
                <h1>NodeJS</h1>
              </div>





              <div className='about-tech-tile'>
                <a ><img src={mysql} alt="MySQL" className='about-tech-pic' /></a>
                <h1>MySQL</h1>
              </div>
              

              
              <div className='about-tech-tile'>
                <a ><img src={html} alt="HTML" className='about-tech-pic' /></a>
                <h1>HTML</h1>
              </div>

              <div className='about-tech-tile'>
                <a ><img src={css} alt="CSS" className='about-tech-pic' /></a>
                <h1>CSS</h1>
              </div>

              <div className='about-tech-tile'>
                <a ><img src={react} alt="React.js" className='about-tech-pic' /></a>
                <h1>React.js</h1>
              </div>

              <div className='about-tech-tile'>
                <a ><img src={cpp} alt="C++" className='about-tech-pic' /></a>
                <h1>C++</h1>
              </div>



              
              <div className='about-tech-tile'>
                <a ><img src={gitvcs} alt="Git" className='about-tech-pic' /></a>
                <h1>Git</h1>
              </div>



            </div>


















            </div>
          </>
        )}

        {activeButton === 'projects' && (
          <>
          
          <p className='project-login-text'>*Requires log in</p>
            <div className="project-content">

            
              <h1>Personal Projects</h1>
              <div className='projects-list'>

                <div className="project-tile" >
                  <div className='project-tile-title'>
                    <div className='project-tile-title-left'>
                      <h1>Scheduler</h1>
                      <p>React.js, NodeJS, MySQL, CSS</p>
                    </div>
                    <p>*</p>
                  </div>
                  <p className='project-login-text' >An application for scheduling events, tasks, meetings, etc. It features a calendar and to-do list to track which events are coming up or are past due. Each user has a unique list of tasks associated with themselves.</p>
                  <div className='project-tile-buttons'>
                    <button className="project-buttons" onClick={handleOpenScheduler}>Open</button>
                    <a href="https://github.com/nicomilette/Scheduler" target='_blank'><img src={git} alt="Open GitHub Page" className='git' /></a>
                  </div>
                </div>



            

                

                
                <div className="project-tile" >

                  <div className='project-tile-title'>
                    <div className='project-tile-title-left'>
                      <h1>Instant Messenger</h1>
                      <p>React.js, NodeJS, MySQL</p>
                    </div>
                    <p>*</p>
                  </div>
                  <p className='project-login-text' >An application for sending direct messages to other users. </p>
                  <div className='project-tile-buttons'>
                    <button className="project-buttons" onClick={handleOpenInstantMessenger}>Open</button>
                    <a href="https://github.com/nicomilette/Scheduler" target='_blank'><img src={git} alt="Open GitHub Page" className='git' /></a>
                  </div>
                </div>
              
                
                




              </div>
              <h1>University Projects</h1>
              
              <div className='projects-list'>
                <div className="project-tile" >

                  <div className='project-tile-title'>
                    <div className='project-tile-title-left'>
                      <h1>Binary Expression Tree</h1>
                      <p>C++</p>
                    </div>
                    <p></p>
                  </div>
                  <p className='project-login-text' >A binary expression tree template class built for storing and evaluating postfix expressions. The program makes use of traversal algorithms to ease data retreival and manipulation. It is able to parse infix inputs using tokenization and convert them into an evaluable postfix expression.</p>
                  <div className='project-tile-buttons'>
                    <a href="https://github.com/nicomilette/BinaryExpressionTree" target='_blank'><img src={git} alt="Open GitHub Page" className='git' /></a>
                  </div>
                </div>


                 <div className="project-tile" >

                  <div className='project-tile-title'>
                    <div className='project-tile-title-left'>
                      <h1>Hash Table Template Class</h1>
                      <p>C++</p>
                    </div>
                    <p></p>
                  </div>
                  <p className='project-login-text' >A hash table template class created for time analysis testing. It is capable of manipulating a set of key value pairs through inserting, deleting, searching, etc. It uses seperate chaining for collision detection.</p>
                  <div className='project-tile-buttons'>
                    <a href="https://github.com/nicomilette/HashTable-CPP-Template" target='_blank'><img src={git} alt="Open GitHub Page" className='git' /></a>
                  </div>
                </div>


                
                </div>


            </div>
            
          
          </>
        )}

        {activeButton === 'blog' && (
          <>
          
            {usernameVerified && (

              <>

                <div className='blog-content'>
                  <button className='new-blogpost-button' onClick={handleNewBlogPostPopup}>New Post</button>
                  
                </div>
              </>
            )}



          {renderBlogPostsByMonth()}
          </>
        )}


        
      </div>


      {(showNewBlogPostPopup && activeButton === 'blog') && (
        <div className="blog-popup">
        
          <textarea
          placeholder="New blog post"
          value={blogText}
          onChange={(e) => setBlogText(e.target.value)}
          ></textarea>


        <div className='blog-popup-buttons'>
          <button onClick={handlePostNewBlog}>Post</button>
          <button onClick={() => setShowNewBlogPostPopup(false)}>Close</button>
        </div>





        </div>

      )}


      {(showExipiredPopup) && (
        <div className="expired-popup">
          <p>Your session has expired. You will be logged-out.</p>


        <div className='expired-popup-buttons'>
          <button onClick={handleOpenLogIn}>Okay</button>
        </div>





        </div>

      )}

      </div>

    </div>






  );
}

export default Main;
